<template>
    <section>
        <div class="grid grid-cols-1 gap-x-20 gap-y-5 lg:gap-y-10 mx-auto mt-10 items-center text-center md:w-11/12 md:text-left xl:w-4/5 md:grid-cols-2">
            <div class="order-1">
                <img
                decoding="async"
                src="../assets/Möbel.jpg"
                title=""
                alt=""
                loading="lazy"
                class="rounded-3xl"
                />
            </div>

            <div class="order-2">
                <h2 class="mt-5 text-3xl xl:text-4xl font-bold mx-auto md:mx-0 w-11/12 md:w-1/1">Eure Vorteile durch die Zusammenarbeit mit uns</h2>
                <p class="my-5 xl:text-lg px-2 sm:p-0">
                    Über <b>111</b> Hersteller & Lieferanten und Zusammenarbeit mit einigen großen Kataloganbietern ermöglichen uns ein sehr umfassendes Sortiment, darüber hinaus sind wir sind frei von Bindung an irgendwelche speziellen Kataloge oder Einschränkungen.
                </p>
                <p class="my-5 xl:text-lg px-2 sm:p-0">   
                    Möbel können in Standardmaßen gewählt werden, oder werden falls erforderlich, individuell auf Maß im Wunschdekor gefertigt .
                </p>
            </div>

            <div class="order-4 md:order-3">
                <div>
                    <h2 class="mt-5 text-3xl xl:text-4xl font-bold">Unser Lager</h2>
                    <p class="my-5 xl:text-lg px-2 sm:p-0">
                        In unserem Ausstellungsraum / Lager seid ihr eingeladen nach Herzenslust Spiele zu testen, Bücher zu lesen, Kreativ-, Beschäftigungs- und Motorikmaterial kennenzulernen, und bei Gefallen auch sofort zum Mitnehmen um direkt am nächsten Tag in der Kita loslegen zu können.  Hier im Lager haben wir auch immer einiges an Sonderposten zu Sonderpreisen. (Bitte Termin vereinbaren!)
                    </p>
                </div>
            </div>

            <div class="order-3 md:order-4">
                <img
                decoding="async"
                src="../assets/Lieferanten.jpg"
                title="Mother during home office and son during homeschooling"
                alt="Mother during home office and son during homeschooling"
                loading="lazy"
                class="rounded-3xl"
                />
            </div>

            <div class="order-6">
                <div>
                    <h2 class="mt-10 text-3xl xl:text-4xl font-bold mx-auto md:mx-0 w-11/12 md:w-1/1">Was bekommt ihr über uns ?</h2>
                    <ul class="list-disc my-5 xl:text-lg px-2 sm:p-0">
                        <li>Erste Hilfe /HygieneArtikel </li>
                        <li>Aussenbereich-Ausstattung </li>
                        <li>Spielgeräte </li>
                        <li>Sonnenschutz </li>
                        <li>Kletterlandschaften etc. </li>
                        <li>Fahrzeuge</li>
                        <li>Möbel </li>
                        <li>Bücher </li>
                        <li>Spiele </li>
                        <li>Bastelmaterial </li>
                        <li>Integrationsberdarf</li>
                        <li>Montessorimaterial </li>
                        <li>Polster /Bettchen Sofas / Matten </li>
                        <li>Teppiche </li>
                        <li>Turngeräte </li>
                        <li>Musikinstrumente </li>
                        <li>Geschirr </li>
                        <li>Konstruktionsmaterial </li>
                        <li>Büros und Personalräume </li>
                        <li>Werkräume </li>
                        <li>Snoezel(t)räume</li>
                        <li>Wickeltische / Auflagen </li>
                        <li>Garderoben </li>
                        <li>Erzieherstühle </li>
                        <li>Experimente  / MINT BNE Lernen Forschen </li>
                        <li>Akustik-Elemente zur Lärmreduzierung </li>
                        <li>robhoc ®</li>
                    </ul>
                </div>
            </div>

            <div class="order-5">
                <div class="grid-1 space-y-5">
                    <img
                decoding="async"
                src="../assets/Lager1.jpg"
                title=""
                alt=""
                class="rounded-3xl"
                    />
                    <img
                decoding="async"
                src="../assets/Lager2.jpg"
                title=""
                alt=""
                class="rounded-3xl"
                    />
                </div>
            </div>
        </div>
        <div class="mt-10 w-11/12 xl:w-3/5 mx-auto bg-rskiga-orange px-5 py-2 rounded-3xl">
            <h2 class="mt-5 text-3xl md:text-4xl font-bold">Nachhaltigkeit und Verantwortung - NATÜRLICH !</h2>
            <p class="my-5 xl:text-lg px-2 sm:p-0">
                Die Fertigung der Möbel, Teppiche, Polster und Sofas in Deutschland, erfolgt überwiegend in Familienbetrieben oder mittelständischen Unternehmen, mit denen wir schon viele Jahre erfolgreich und sehr zufrieden zusammen arbeiten.
                Ihr bekommt deutsche Premium-Produkte zum Top-Preis und das bei möglichst kurzen Transportwegen.
                Die gängigen Sicherheitsstandards und TÜV-Zertifikate sind für uns selbstverständlich.
            </p>
        </div>
    </section>
</template>

<script>
    export default {
    name: "HomeSectionFour",
    };
</script>
<style scoped></style>
