<template>
    <div class="bg-rskiga-orange bg-opacity-100 py-10">
        <div class="flex flex-wrap md:flex-nowrap gap-5 xl:gap-10 w-11/12 xl:w-4/5 mx-auto ">
            <div class="bg-white rounded-3xl p-4">
                <h2 class="text-4xl font-bold">
                    Das sind wir
                </h2>
                <p class="mt-5 lg:text-lg">
                    Unser Unternehmen wurde 1992 von Regina Schmitz gegründet. Sie ist staatlich anerkannte Erzieherin mit Leitungserfahrung. 
                    Daher auch der Name R.S. (Regina Schmitz) und damals hieß die Kita eben noch KIGA.</p>
                <p class="mt-5 lg:text-lg">
                    Viele Jahre hat sie alles allein gestemmt, immer wieder mit Unterstützung ihrer Töchter Eva und Henrike, welche damit groß geworden sind. 
                    Seit 2015 ist Henrike intensiver ins Unternehmen eingestiegen und hat es letztlich 2017 als Gesellschafterin übernommen. 
                    Nun ist es umgekehrt, dass Henrike das Meiste alleine macht und  Regina hinter den Kulissen bei der Buchhaltung und Planung, Angeboten und 
                    bei Messe-Besuchen mit dabei ist und mit ihrem Wissen und Erfahrungsschatz unterstützend zur Seite steht.
                </p>
                <p class="mt-5 lg:text-lg">
                    Zudem haben wir fleissige Helfer im Lager, Auslieferung und bei der Technik.
                </p>
                <p class="mt-5 lg:text-lg text-rskiga-orange font-bold"> 
                    Unsere Aufgabe ist es, Euch und dem Team den Alltag in der Kita zu erleichtern !
                </p>
            </div>
            <div class="bg-white rounded-3xl p-4">
                <h2 class="text-4xl font-bold">
                    Das machen wir
                </h2>
                <p class="mt-5 lg:text-lg">
                    Wir bieten <b>ALLES</b> aus <b>EINER</b> Hand rund um und für Eure Kita. 
                </p>
                <p class="mt-5 lg:text-lg">
                    Angefangen bei der Planung gemeinsam mit Träger und Architekt für eine neue Kita bis hin zur vollständig möblierten und eingerichtete Einrichtung - - - 
                    Und auch bei alltäglichen Bestellungen von Spielen, Büchern, Material und allem was im Kita Alltag an Wünschen anfällt stehen wir Euch zur Seite.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeSectionTwo",
    }
</script>

<style scoped></style>
