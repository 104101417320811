<template>
    <div>
        <HomeSection1 />
        <HomeSection2 />
        <HomeSection3 />
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "@/components/Footer.vue";
    import HomeSection1 from '@/components/HomeSection1.vue'
    import HomeSection2 from '@/components/HomeSection2.vue'
    import HomeSection3 from '@/components/HomeSection3.vue'

    export default {
        name: "HomePage",
        components: {
            Footer,
            HomeSection1,
            HomeSection2,
            HomeSection3,  
        }
    };
</script>
