<template>
    <div>
        <div class="w-3/4 mx-auto my-5">
            <div class="flex justify-between">
                <div>
                    <p class="w-60 text-2xl xl:text-4xl font-semibold leading-normal">Impressum</p>
                    <p class="mt-10">R.S. Kindergartenbedarf GmbH </p>
                    <p>HenRike Grässel </p>
                    <p>Taunusstraße 50 </p>
                    <p>65375 Oestrich-Winkel  </p>
                    <p class="mt-5">Anrufe Tel: 06723-804 89 20 / Fax: 06723-804 89 21</p>
                    <p class="mt-5">Message und Notfall-Kontakt 0175-2681788  </p>
                    <p class="mt-5">rike@rskiga.de  </p>
                    <p>graessel@rskiga.de</p>
                    <p class="mt-5">UsT-Id. Nr.  DE 204189930</p>
                    <p>Eingetragen im Handelsregister Wiesabden HRB 11514</p>
                    <p>Gesellschafterin: Henrike Grässel</p>
                    <p>Geschäftsführerinnen: Henrike Grässel und Regina Carius</p>
                </div>
                <div class="">
                    <img
                        decoding="async"
                        src="../assets/RS_Stab_orange_vektor_geneigt.svg"
                        class=""
                        alt=""
                        loading="lazy"
                    />
                </div>
            </div>

            <p class="mt-5 font-bold">Haftungsausschluss</p>
            <p class="italic">Haftung für Inhalte</p>
            <p>
            Die Inhalte unserer Seiten wurden mit größter
            Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
            Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
            sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
            fremde Informationen zu überwachen oder nach Umständen zu forschen, die
            auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
            dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            </p>

            <p class="mt-5 italic">Haftung für Links</p>
            <p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <p class="mt-5 font-bold">Urheberrecht</p>
            <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
            Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "@/components/Footer.vue";

  export default {
    name: "impressumPage",
    components: Footer
  };
</script>
