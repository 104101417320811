<template>
  <div
    id="app"
    class=""
  >
  <router-view class="h-screen overflow-x-hidden" />
  </div>
</template>
<script>

export default {
  name: "App",
  components: {
  },
};
</script>
<style scoped>
#app {
  min-height: 100vh;
}

</style>
