<template>
    <div class="lg:mt-0">
        <div class="bg-rskiga-orange text-center md:text-2xl xl:text-3xl py-2 font-bold text-white">
            <span>ACHTUNG BAUSTELLE … WEBSITE IST NOCH IM AUFBAU… </span><span class="block xl:inline-block">Danke für Euer Verständnis.</span>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 place-items-center text-center font-bold mx-auto lg:w-11/12 xl:w-3/4">
            <div class="text-tu-darkblue xl:text-left">
                <!-- Text -->
                <h1 class="text-4xl md:text-6xl mt-5 lg:mt-0">R.S. Kindergartenbedarf GmbH</h1>
                <p class="mt-5 underline xl:no-underline">
                    <a class="text-2xl" href="mailto:rike@rskiga.de">rike@rskiga.de</a>
                </p>
            </div>
            <!-- Image -->
            <div class="">
                <img
                    decoding="async"
                    src="../assets/RS_Stab_orange_vektor_geneigt.svg"
                    class=""
                    alt=""
                    loading="lazy"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    name: "HomeSectionOne",
    };
</script>

<style scoped></style>
