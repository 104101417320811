<template>
  <div class="pt-16">
    <div class="w-full bg-rskiga-orange py-6">
      <div
        class="xl:flex lg:flex text-center"
      >
        <div
          class="xl:w-3/6 lg:w-3/6 sm:w-full mb-6 xl:mb-0 lg:mb-0"
        >
          <p>R.S. Kindergartenbedarf GmbH</p>
        </div>
        <div class="xl:w-3/6 lg:w-3/6 sm:w-full">
          <ul class="xl:flex lg:flex md:flex sm:flex justify-around">
            <li
              class="hover:text-gray-900 mb-3 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0"
            >
              <router-link
                to="/impressum"
                @click.native="scrollToTop()"
                class="text-base leading-none hover:text-tu-lightblue"
                >Impressum</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "FooterComponent",
        methods: { 
        scrollToTop() {
            window.scrollTo(0,0);
        }
        }
    };
</script>

<style scoped></style>
